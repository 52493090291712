export const color = {
  theme: '#656565',
  // linear-gradient(#e66465, #9198e5);
  edit: '#ff7b54',
  submit: '#7eca9c',
  clear: '#707070',
  remove: '#d35d6e',
  search: '#1890ff',
  red: '#FF0000',
  logout: '#737373',

  yellow: '#F4D35E',
  blackTheme: '#24231D',
  orangeTheme: '#FD8103',
  white: '#ffffff',
  blue: '#4096ff',
  line: '#06c755',
  delete: '#E53935',
  search2: '#1E3E62',
  green: '#60D936',
  gray: '#9e9e9e',
  red2: '#CA3E47',
  helper: '#878787',
  red3: '#E44160',
};
