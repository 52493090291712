import React from 'react';
import {
  createColumnText,
  createColumnDate,
  widthOptions,
} from '@/components/table/function/createColumn';
import { ROUTES_PATH } from '@/resources/routes-name';
import {
  createColumnButtonArr,
  createColumnTag,
} from '../../../../components/table/function/createColumn';
import { CLAIM_STATUS, PROCESS_STATUS } from '../../../../resources/status';
import { Tag } from 'antd';
import { ButtonTheme, ButtonToolTip } from '../../../../components';

export const columns = ({
  onModalMessage = () => {},
  onModalProcessStatus = () => {},
  onModalRemark = () => {},
}) => {
  return [
    createColumnTag(
      'สถานะ',
      { key: 'claimStatus', text: 'claimStatusName', icon: true },
      CLAIM_STATUS,
      {
        width: '150px',
      },
    ),
    createColumnTag(
      'สถานะภายใน',
      { key: 'processStatus', text: 'processStatusName' },
      PROCESS_STATUS,
      {
        width: 220,
        render: (_, record) => {
          const status = PROCESS_STATUS?.find((s) => s.key === record?.processStatus);
          const handleClick = () => {
            onModalProcessStatus(record);
          };

          return (
            <div style={{ cursor: 'pointer' }} onClick={handleClick}>
              <Tag color={status?.color}>{status?.label}</Tag>
            </div>
          );
        },
        filters: PROCESS_STATUS?.map((status) => ({
          text: status?.label,
          value: status?.key,
        })),
        onFilter: (value, record) => record?.processStatus === value,
      },
    ),
    createColumnText('เลขที่เคลม', 'claimCode', widthOptions(150)),
    createColumnText('ชื่อ-นามสกุล', 'fullName', widthOptions(150)),
    createColumnText('ชื่อไลน์', 'lineName', widthOptions(140)),
    createColumnText('เบอร์ติดต่อ', 'telephone', widthOptions(120)),
    createColumnDate('วันที่แจ้ง', 'claimDate', widthOptions(150)),
    createColumnText('รหัสสินค้า', 'itemCode', widthOptions(140)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnText('รุ่นมือถือ', 'mobileModelName', widthOptions(130)),
    createColumnText('เลขพัสดุ', 'trackingNumber', widthOptions(130)),
    createColumnDate('วันที่จัดส่ง', 'shippingDate', widthOptions(150)),
    createColumnText('หมายเหตุ', 'remark', widthOptions(140)),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 230,
      },
      buttonArr: [
        {
          useFor: 'EDIT3',
          render: (text, record) => {
            if (record.claimStatus !== 'TRANSPORTED') {
              return (
                <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
                  <ButtonTheme
                    useFor="EDIT3"
                    onClick={() => {
                      const url = `${ROUTES_PATH.ROUTE_CLAIM_DETAIL.PATH}?code=${record.claimCode}`;
                      window.open(url, '_blank');
                    }}
                  />
                </ButtonToolTip>
              );
            }
          },
        },
        {
          useFor: 'DETAIL',
          render: (text, record) => {
            if (record.claimStatus === 'TRANSPORTED') {
              return (
                <ButtonToolTip toolTip="ดูรายละเอียด" placement="topLeft">
                  <ButtonTheme
                    useFor="DETAIL"
                    onClick={() => {
                      const url = `${ROUTES_PATH.ROUTE_CLAIM_DETAIL.PATH}?code=${record.claimCode}`;
                      window.open(url, '_blank');
                    }}
                  />
                </ButtonToolTip>
              );
            }
          },
        },
        {
          useFor: 'LINE2',
          render: (text, record) => (
            <ButtonToolTip toolTip="ส่งข้อความ" placement="topLeft">
              <ButtonTheme useFor="LINE2" onClick={() => onModalMessage(record)} />
            </ButtonToolTip>
          ),
        },
        {
          useFor: 'EVERNOTE',
          render: (text, record) => (
            <ButtonToolTip toolTip="หมายเหตุ" placement="topLeft">
              <ButtonTheme useFor="EVERNOTE" onClick={() => onModalRemark(record)} />
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
