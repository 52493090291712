import { BiEdit } from 'react-icons/bi';
import { color } from '../color';
import { ImCheckmark } from 'react-icons/im';
import { RiDeleteBin6Line, RiTimeLine } from 'react-icons/ri';
import { Icon } from '../icon';

export const STORE_TYPE = [
  { key: 'FOCUS SHOP', label: 'FOCUS SHOP' },
  { key: 'APPLE HOUSE', label: 'APPLE HOUSE' },
];

export const WARRANTY_STATUS = [
  { key: 'ACTIVE', label: 'ACTIVE', icon: <BiEdit />, iconColor: color.orangeTheme },
  { key: 'CLAIM', label: 'CLAIM', icon: <ImCheckmark />, iconColor: color.search2 },
  { key: 'EXPIRED', label: 'EXPIRED', icon: <RiTimeLine />, iconColor: '#9e9e9e' },
  { key: 'CANCEL', label: 'CANCEL', icon: <RiDeleteBin6Line />, iconColor: '#ff5e5e' },
];

export const CLAIM_STATUS = [
  { key: 'WAIT', label: 'รอการตรวจสอบ', iconColor: color.blue, icon: <Icon.hourglass /> },
  {
    key: 'PREPARING',
    label: 'กำลังเตรียมจัดส่ง',
    iconColor: color.blackTheme,
    icon: <Icon.truck />,
  },
  {
    key: 'TRANSPORTED',
    label: 'จัดส่งเรียบร้อย',
    iconColor: color.line,
    icon: <Icon.paperPlane />,
  },
  { key: 'REJECTED', label: 'ยกเลิก', iconColor: color.red2, icon: <Icon.close /> },
];

export const PROCESS_STATUS = [
  { key: 'WAITING_REVIEW', label: 'รอตรวจสอบแจ้งเคลม', color: color.blue },
  { key: 'WAITING_CHECK_LACK_ITEM', label: 'รอตรวจสอบ ของขาด', color: color.orangeTheme },
  { key: 'INCOMPLETE_INFO', label: 'รอตรวจสอบ รูปไม่ถูกต้อง', color: color.orangeTheme },
  { key: 'WAIT_CONTACT_CUSTOMER', label: 'รอติดต่อลูกค้า', color: color.red3 },
  { key: 'PREPARING_SEND', label: 'กำลังเตรียมพัสดุ', color: '#eecd5a' },
  { key: 'SHIPPED', label: 'จัดส่งเรียบร้อย', color: color.line },
];

export const STORE_STATUS = [
  { key: 'ACTIVE', label: 'ACTIVE' },
  { key: 'INACTIVE', label: 'INACTIVE' },
];

export const ORDER_STATUS = [
  { key: 'ACTIVE', label: 'ลงทะเบียนแล้ว' },
  { key: 'WAIT', label: 'รอลงทะเบียน' },
];

export const ORDER_FROM = [
  { key: 'FOCUS_SHOP', label: 'FOCUS SHOP' },
  { key: 'WEB', label: 'WEB' },
];

export const COUPON_STATUS = [
  { key: 'LINE', label: 'LINE' },
  { key: 'STORE', label: 'STORE' },
];

export const PROMOTION_STATUS = [
  { key: 'ACTIVE', label: 'ACTIVE' },
  { key: 'INACTIVE', label: 'INACTIVE' },
];

export const STATUS_OPTIONS = [
  { key: 'ACTIVE', label: 'ACTIVE' },
  { key: 'INACTIVE', label: 'INACTIVE' },
];

export const MOBILE_BRAND_STATUS = [
  { key: 'ACTIVE', label: 'ACTIVE' },
  { key: 'INACTIVE', label: 'INACTIVE' },
];
