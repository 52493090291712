import { useMutation } from '@tanstack/react-query';
import { POST, POST_WARRANTY_UPDATE } from '../../../../services';
import { openAlert } from '../../../../components/alert/useAlert';

export const onUploadFile = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async ({
      invImageUrl,
      packageImageUrl,
      warrantyId,
      telephone,
      fullName,
      warrantyNumberCode,
      mobileBrandId,
      mobileModelId,
      mapProductId,
      channelBuyId,
      warrantyTypeId,
    }) => {
      const formData = new FormData();
      formData.append('invImage', invImageUrl.originFileObj);
      formData.append('packageImage', packageImageUrl.originFileObj);
      formData.append('warrantyId', warrantyId);
      formData.append('telephone', telephone);
      formData.append('fullName', fullName);
      formData.append('warrantyNumberCode', warrantyNumberCode);
      formData.append('mobileBrandId', mobileBrandId);
      formData.append('mobileModelId', mobileModelId);
      formData.append('mapProductId', mapProductId);
      formData.append('channelBuyId', channelBuyId);
      formData.append('warrantyTypeId', warrantyTypeId);
      const response = await POST(POST_WARRANTY_UPDATE(), formData);
      if (onSuccessCallBack) onSuccessCallBack(response);
      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'แก้ไขข้อมูลสินค้าสำเร็จ' });
    },
  });
