import { GET, GET_WARRANTY_EDIT } from '../../../../services';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useEffect } from 'preact/hooks';

export const useGetWarrantyEdit = ({ warrantyCode, reset, setProducts }) => {
  const [data, setData] = useState();

  const { isLoading, mutate } = useMutation({
    mutationFn: async () => {
      const response = await GET(GET_WARRANTY_EDIT(warrantyCode));
      return response.result;
    },
    onSuccess: (result) => {
      reset({
        ...result?.warranty,
        invImageUrl: [{ uid: result?.warranty?.invImageUrl, url: result?.warranty?.invImageUrl }],
        packageImageUrl: [
          { uid: result?.warranty?.packageImageUrl, url: result?.warranty?.packageImageUrl },
        ],
      });
      setProducts(result?.item?.mobilebrands);
    },
  });

  useEffect(() => {
    if (warrantyCode) {
      mutate();
    }
  }, [warrantyCode]);

  return { dataSource: data, setData, onSearch: mutate, loading: isLoading };
};
