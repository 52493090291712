import { GET, GET_WARRANTY_DETAIL } from '../../../../services';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useEffect } from 'preact/hooks';

export const useGetWarrantyView = ({ warrantyCode, reset }) => {
  const [data, setData] = useState();

  const { isPending, mutate } = useMutation({
    mutationFn: async () => {
      const response = await GET(GET_WARRANTY_DETAIL(warrantyCode));
      return response.result;
    },
    onSuccess: (result) => {
      reset({
        ...result?.warranty,
        invImageUrl: [{ uid: result?.warranty?.invImageUrl, url: result?.warranty?.invImageUrl }],
        packageImageUrl: [
          { uid: result?.warranty?.packageImageUrl, url: result?.warranty?.packageImageUrl },
        ],
      });
    },
  });

  useEffect(() => {
    if (warrantyCode) {
      mutate();
    }
  }, [warrantyCode]);

  return { dataSource: data, setData, onSearch: mutate, loading: isPending };
};
