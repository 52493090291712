export const FORM_CLAIM_DETAIL_PERSONAL = ({
  onEnter,
  provinceOptions,
  districtOptions,
  subDistrictOptions,
}) => {
  const span = 24;
  return [
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'TEXT',
      placeholder: '',
      span,
    },
    {
      name: 'telephone',
      label: 'หมายเลขโทรศัพท์',
      type: 'TEXT',
      placeholder: '',
      span,
    },
    {
      name: 'addressFullText',
      label: 'ที่อยู่',
      type: 'TEXT',
      span,
    },
    {
      name: 'zipCode',
      label:
        'รหัสไปรษณีย์ *** (หากต้องการเปลี่ยนที่อยู่ให้ทำการกรอกรหัสไปรษณีย์ใหม่แล้วกด Enter) ***',
      type: 'INPUT',
      placeholder: '',
      span,
      onEnter,
    },
    {
      name: 'provinceId',
      label: 'จังหวัด',
      type: 'DROPDOWN',
      properties: {
        options: provinceOptions || [],
      },
      span,
    },
    {
      name: 'districtId',
      label: 'เขต',
      type: 'DROPDOWN',
      properties: {
        options: districtOptions || [],
      },
      span,
    },
    {
      name: 'subDistrictId',
      label: 'อำเภอ',
      type: 'DROPDOWN',
      properties: {
        options: subDistrictOptions || [],
      },
      span,
    },
    {
      name: 'other',
      label: 'เพิ่มเติม',
      type: 'INPUT',
      placeholder: '',
      span,
    },
  ];
};
export const FORM_CLAIM_EDIT_DETAIL_PICTURE = () => {
  const accept = [`image/jpeg`, 'image/png', 'image/jpg', 'image/bmp', 'image/gif'];
  return [
    {
      name: 'claimImageUrl',
      label: 'รูปภาพสินค้าเคลม (.png, .jpg, .jpeg, .bmp, .gif)',
      type: 'UPLOAD',
      accept: accept.join(','),
      listType: 'picture-card',
      span: 24,
      maxCount: 1,
    },
  ];
};

export const FORM_CLAIM_DETAIL_CLAIM = ({ breakdownList }) => {
  const span = 24;
  return [
    {
      name: 'claimCode',
      label: 'เลขที่การเคลม',
      type: 'TEXT',
      placeholder: '',
      span,
    },
    {
      name: 'claimId',
      label: 'Claim ID',
      type: 'HIDDEN',
      span,
    },
    {
      name: 'warrantyCode',
      label: 'รหัสลงทะเบียนสินค้า',
      type: 'TEXT',
      span,
    },
    {
      name: 'claimDate',
      label: 'วันที่แจ้งเคลม',
      type: 'TEXT',
      span,
    },
    {
      name: 'productName',
      label: 'ชื่อสินค้า',
      type: 'TEXT',
      span,
    },
    {
      name: 'mobileBrandName',
      label: 'ยี่ห้อ',
      type: 'TEXT',
      span,
    },
    {
      name: 'mobileModelName',
      label: 'รุ่น',
      type: 'TEXT',
      span,
    },
    {
      name: 'breakdownId',
      label: 'อาการเสีย',
      type: 'DROPDOWN',
      properties: {
        options: breakdownList || [],
      },
      span,
    },
    {
      name: 'detail1',
      label: 'รายละเอียด',
      type: 'INPUT',
    },
    {
      name: 'remark',
      label: 'หมายเหตุ (ระบุได้ไม่เกิน 500 ตัวอักษร)',
      type: 'INPUT_AREA',
      span,
      rules: {
        maxLength: 500,
      },
    },
    {
      name: 'claimedDays',
      label: 'จำนวนวันที่แจ้งเคลมมาแล้ว',
      type: 'TEXT',
      span,
    },
    {
      name: 'remainingDays ',
      label: 'จำนวนวันคงเหลือ',
      type: 'TEXT',
      span,
    },
  ];
};
