import dayjs from 'dayjs';

export const FORM_SEARCH = ({ onEnter }) => {
  return [
    {
      name: 'search',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      type: 'INPUT',
      placeholder: '',
      span: 15,
      helper: 'Tips : สามารถค้นหาด้วย รหัสเคลม , รหัสรับประกัน, เบอร์ , รหัสลูกค้า , ชื่อลูกค้า',
      onEnter,
    },
    {
      name: 'startDate',
      label: 'ค้นหาข้อมูลรายการเคลมสินค้าโดยอิงวันที่',
      type: 'DATE_RANGE',
      placeholder: '',
      span: 9,
      rules: {
        required: true,
      },
    },
  ];
};

export const FORM_MESSAGE = () => {
  return [
    {
      name: 'message',
      label: 'ข้อความ (ระบุได้ไม่เกิน 500 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      span: 24,
      rules: {
        required: true,
        maxLength: 500,
      },
    },
  ];
};

export const FORM_PROCESS_STATUS = ({ processStatusList = [] }) => {
  const span = 24;
  return [
    {
      name: 'processStatus',
      type: 'DROPDOWN',
      span,
      properties: {
        options: processStatusList.map((status) => ({
          label: status.label,
          value: status.value,
        })),
        defaultValue:
          processStatusList.find((status) => status.label === 'processStatusName')?.value || '',
      },
    },
  ];
};

export const FORM_REMARK = () => {
  return [
    {
      name: 'remark',
      label: 'ข้อความ (ระบุได้ไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      span: 24,
      rules: {
        required: true,
        maxLength: 250,
      },
    },
  ];
};

export const useDefault = (initialValues = {}) => {
  const defaultValues = {
    fullName: initialValues.fullName || '',
  };
  return {
    defaultValues,
  };
};

export const searchDefaultValues = {
  startDate: [dayjs().startOf('month'), dayjs()],
};
