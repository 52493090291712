export const FORM_USER_REGISTRATION_WARRANTY = () => {
  const span = 24;
  return [
    {
      name: 'telephone',
      label: 'เบอร์ติดต่อ',
      type: 'INPUT_PHONE',
      placeholder: '',
      span,
      maxLength: 10,
      rules: {
        required: true,
        pattern: /^[0-9]*$/,
      },
    },
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT',
      placeholder: '',
      span,
    },
    {
      name: 'createDate',
      label: 'วันที่เริ่มรับประกัน',
      type: 'DATE_PICKER',
      placeholder: '',
      format: 'YYYY-MM-DD',
      span,
      disabled: true,
    },
    {
      name: 'expireDate',
      label: 'วันหมดอายุ',
      type: 'DATE_PICKER',
      format: 'YYYY-MM-DD',
      placeholder: '',
      span,
      disabled: true,
    },
  ];
};

export const FORM_PRODUCT_REGISTRATION_WARRANTY = ({
  mobileBrandOptions,
  modelOptions,
  productOptions,
  channelBuyList,
  warrantyTypesList,
}) => {
  const span = 24;
  const accept = [`image/jpeg`, 'image/png', 'image/jpg', 'image/bmp', 'image/gif'];
  return [
    {
      name: 'warrantyCode',
      label: 'รหัสลงทะเบียนสินค้า',
      type: 'INPUT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'warrantyNumberCode',
      label: 'รหัสรับประกันสินค้า',
      type: 'INPUT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'mobileBrandId',
      label: 'ยี่ห้อมือถือ',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: mobileBrandOptions || [],
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'mobileModelId',
      label: 'รุ่นมือถือ',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: modelOptions || [],
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'mapProductId',
      label: 'ชื่อสินค้า',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: productOptions || [],
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'channelBuyId',
      label: 'ช่องทางการซื้อขาย',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: channelBuyList || [],
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'warrantyTypeId',
      label: 'ประเภทรับประกัน',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: warrantyTypesList || [],
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'infoPicture',
      label: 'ชนิดรูปภาพที่ต้องการ (.png, .jpg, .jpeg, .bmp, .gif)',
      type: 'TEXT',
      span,
    },
    {
      name: 'invImageUrl',
      label: 'รูปใบเสร็จ',
      type: 'UPLOAD',
      accept: accept.join(','),
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
    },
    {
      name: 'packageImageUrl',
      label: 'รูปสินค้า',
      type: 'UPLOAD',
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
    },
  ];
};
