import React from 'react';
import { ContainerButton } from '@/styles/global-style';
import { StyledContainer } from '../registration-product/styles';
import { ButtonTheme, CardTheme, Gap, ResponsivePageLayout, TextLarge } from '@/components';
import ButtonExcelEmbedded from '@/components/report-excel/ButtonExcelEmbedded';
import { useEmployeeList } from './hooks/useEmployeeList';
import { FORM_SEARCH, searchDefaultValues } from './forms';
import { useDeleteWarrantyQuery } from './hooks/useDeleteWarrantyQuery';
import { useForm } from 'react-hook-form';
import { Icon } from '@/resources';
import { columns } from './columns';
import { CustomImage } from '@/functions/Image';
import { ROUTES_PATH } from '../../../resources/routes-name';
import { navigateTo } from '../../../utils/navigation';
import { SpaceBetween } from '../../manage-data/user-info/styles';
import { RowSearchLayout } from '../../../components/page-layout/views/RowSearchLayout';
import { InfoRegistration } from './views/InfoRegistration';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'preact/hooks';
import { useGetWarrantyFull } from './hooks/useGetWarrantyFull';

export const Registration = () => {
  const location = useLocation();
  const [searchType, setSearchType] = useState(null);
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const formSearchObj = useForm({ defaultValues: searchDefaultValues || location.state });

  const {
    dataSource,
    onFetch,
    isLoading: isLoading,
  } = useEmployeeList({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const {
    dataSource: dataWarrantyFull,
    onFetchFull,
    loading: isPending,
  } = useGetWarrantyFull({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const gotoRegistrationWarranty = () => {
    navigateTo({ pathname: ROUTES_PATH.ROUTE_REGISTRATION_WARRANTY.PATH });
  };

  const { mutate: handleDelete } = useDeleteWarrantyQuery({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    if (location.state && isInitialFetch) {
      formSearchObj.handleSubmit(onFetch)();
      setIsInitialFetch(false);
    } else {
      onFetch();
    }
  }, [location.state, isInitialFetch]);

  const onSearchByField = (values) => {
    if (values.search) {
      setSearchType('search');
      onFetchFull(values);
    } else if (values.startDate) {
      setSearchType('date');
      onFetch(values);
    }
  };

  const formSearch = FORM_SEARCH({ onEnter: formSearchObj.handleSubmit(onSearchByField) });

  return (
    <StyledContainer>
      <TextLarge text="รายงานการลงทะเบียน" />
      <CardTheme>
        <ContainerButton right>
          <ButtonTheme
            useFor="WARRANTY"
            title="ลงทะเบียนสินค้า"
            onClick={() => gotoRegistrationWarranty()}
          />
        </ContainerButton>
        <RowSearchLayout
          props={{
            title: 'ค้นหารายงาน',
            formSearch,
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            onSearch: formSearchObj.handleSubmit(onSearchByField),
            onClearCustom: () => {
              formSearchObj.reset(searchDefaultValues);
            },
          }}
        >
          <InfoRegistration />
        </RowSearchLayout>
        <ResponsivePageLayout
          tableLayout={{
            columns: columns({ handleDelete }),
            dataSource: searchType === 'search' ? dataWarrantyFull : dataSource,
            loading: isLoading || isPending,
            hasPagination: true,
            rowKey: 'rowNumber',
            expandable: {
              expandedRowRender: (record) => (
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <CustomImage
                    src={record.invImageUrl}
                    alt="รูปใบเสร็จ"
                    preview
                    width={80}
                    style={{ borderRadius: 8 }}
                  />
                  <CustomImage
                    src={record.packageImageUrl}
                    alt="รูปสินค้า"
                    preview
                    width={80}
                    style={{ borderRadius: 8, marginLeft: 20 }}
                  />
                </div>
              ),
              rowExpandable: (record) => record.name !== 'Not Expandable',
            },

            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    header={columns({})}
                    data={searchType === 'search' ? dataWarrantyFull : dataSource}
                    filename="รายงานการลงทะเบียน"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
      <Gap />
    </StyledContainer>
  );
};
