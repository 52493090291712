import { create } from 'apisauce';
import { getQueryString, getQueryStringIgnoreEmpty } from '../functions';
import { getCookies } from '../store/useCookies';
import { env } from '../utils/env/config';
import { navigateTo } from '../utils/navigation';
import { ROUTE_LOGIN_MANUAL } from '../resources/routes-name';
import { Modal } from 'antd';

export const MODE_API = {
  DEVELOPMENT: env.url_dev,
  UAT: env.url_uat,
  PRODUCTION: env.url_prd,
};
export const CONNECT_API = MODE_API[`${env.node_env}`.toUpperCase()];

const api = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 20000, // 20 seconds
});

api.axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ECONNABORTED') {
      navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
    }
    return Promise.reject(error);
  },
);

const apiupload = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */

const checkAccessToken = (token) => {
  if (!token) {
    Modal.confirm({
      title: 'แจ้งเตือน',
      content:
        'ไม่สามารถใช้งานได้ กรุณาออกจากระบบ แล้วลองเข้าใหม่อีกครั้ง หากเข้าไม่ได้ให้ติดต่อ Admin',
      okText: 'ตกลง',
      onOk: () => {
        navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
      },
    });
    return false;
  }
  return true;
};

export const POST = (path, obj, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          if (response.status === 401) {
            navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
          } else {
            response.data
              ? reject(response.data)
              : reject({ success: false, message: response.problem });
          }
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
        } else {
          reject(err);
        }
      });
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    if (!checkAccessToken(token)) {
      return;
    }

    api
      .get(
        path,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      )
      .then((response) => {
        if (response.status === 401) {
          navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
        } else if (response.status === 200) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
        } else {
          reject(err);
        }
      });
  });

export const UPLOAD = (path, formdata, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    if (!checkAccessToken(token)) {
      return;
    }
    apiupload
      .post(path, formdata, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.message });
        }
      })
      .catch((err) => reject(err));
  });

/* ################################################## URL ################################################## */
export const LOGIN = `/warranty/abm/api/v2/admin/auth/login`;
export const LOGIN_GOOGLE = `/authen/login-google`;
export const REGISTER = `/authen/register`;
export const GET_PROFILE = `/warranty/abm/api/v2/admin/auth/me`;
export const GET_ALL_PRODUCT = `/product/get-all`;
export const GET_WARRANTY = (obj) =>
  `/warranty/abm/api/v2/admin/warranties/by-date?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_WARRANTY_EDIT = (warrantyCode) =>
  `/warranty/abm/api/v2/admin/warranties/code/${warrantyCode}`;
export const GET_PRODUCT_SEARCH = (obj) => `/product/search?${getQueryString(obj)}`;
export const GET_CUSTOMERS_TEL = (telephone) =>
  `/warranty/abm/api/v2/admin/customers/tel/${telephone}`;
export const GET_WARRANTY_REGISTER = (warrantyNumberCode) =>
  `/warranty/abm/api/v2/admin/serial-number/verify/${warrantyNumberCode}`;
export const GET_CHANNEL_BUYS = () => `/warranty/abm/api/v2/admin/options/channel-buys`;
export const GET_WARRANTY_TYPES = () => `/warranty/abm/api/v2/admin/options/warranty-types`;
export const POST_WARRANTY_ADD = () => `/warranty/abm/api/v2/admin/warranties/add`;
export const POST_WARRANTY_UPDATE = () => `/warranty/abm/api/v2/admin/warranties/update`;
export const POST_CANCEL_WARRANTY = () => `/warranty/abm/api/v2/admin/warranties/cancel`;
export const GET_CLAIMS_CUSTOMER = (telephone) =>
  `/warranty/abm/api/v2/admin/claims/customers/verify/${telephone}`;
export const GET_CLAIMS_ZIPCODE = (zipcode) =>
  `/warranty/abm/api/v2/admin/options/addresses/zipcode/${zipcode}`;
export const POST_CLAIMS_INSERT = () => `/warranty/abm/api/v2/admin/claims/insert`;
export const GET_CLAIM_DETAIL = (claimCode) =>
  `/warranty/abm/api/v2/admin/claims/code/${claimCode}`;
export const GET_CLAIM_PROCESS = () => `/warranty/abm/api/v2/admin/options/status/claim-process`;
export const POST_CLAIM_REJECT = () => `/warranty/abm/api/v2/admin/claims/update/status/reject`;
export const POST_CLAIM_UPDATE = () => `/warranty/abm/api/v2/admin/claims/update/info`;
export const GET_CLAIM_BILL = () => `/warranty/abm/api/v2/admin/claims/cover-sheets`;
export const GET_CUSTOMER_GROUPS = () => `/warranty/abm/api/v2/admin/options/customer-groups`;
export const GET_PROMOTION_FULL_SEARCH = () => `/warranty/abm/api/v2/admin/promotions/detail`;
export const GET_STORE_TYPE = () => `/warranty/abm/api/v2/admin/stores/types`;
export const POST_DESTROT_STORE = () => `/warranty/abm/api/v2/admin/stores/destroy`;
export const GET_REPORT_MEMBER = (obj) =>
  `/warranty/abm/api/v2/admin/reports/members/orders?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_REPORT_HISTORY = (obj) =>
  `/warranty/abm/api/v2/admin/reports/coupons/history?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_REPORT_FULL_MEMBER = (obj) =>
  `/warranty/abm/api/v2/admin/reports/members/orders/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_REPORT_FULL_HISTORY = (obj) =>
  `/warranty/abm/api/v2/admin/reports/coupons/history/full-search?${getQueryStringIgnoreEmpty(
    obj,
  )}`;
export const GET_REPORT_FREQUENCY = (obj) =>
  `/warranty/abm/api/v2/admin/reports/coupons/frequency?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_REPORT_FULL_FREQUENCY = (obj) =>
  `/warranty/abm/api/v2/admin/reports/coupons/frequency/full-search?${getQueryStringIgnoreEmpty(
    obj,
  )}`;

export const GET_STORE_FULL_SEARCH = (obj) =>
  `/warranty/abm/api/v2/admin/stores/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_SUPER_MEMBER = (obj) =>
  `/warranty/abm/api/v2/admin/member-orders/by-date?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_SUPER_MEMBER_FULL_SEARCH = (obj) =>
  `/warranty/abm/api/v2/admin/member-orders/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const POST_CANCEL_MEMBER = () => `/warranty/abm/api/v2/admin/member-orders/destroy`;
export const GET_PROMOTIONS_TYPE = () => `/warranty/abm/api/v2/admin/promotions`;
export const GET_CHANNEL_MEMBER = () => `/warranty/abm/api/v2/admin/options/member-orders/channel`;
export const GET_ORDER_STATUS = () => `/warranty/abm/api/v2/admin/options/member-orders/status`;
export const GET_USER_MANAGE = (obj) =>
  `/warranty/abm/api/v2/admin/auth/users?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_USER_PRIVILEGE = (userId) =>
  `/warranty/abm/api/v2/admin/auth/users/privileges/for-user/${userId}`;
export const GET_CATEGORY_LIST = () => `/warranty/abm/api/v2/admin/options/categories`;
export const POST_DESTROT_CATEGORY = () => `/warranty/abm/api/v2/admin/options/categories/destroy`;
export const GET_MOBILE_BRAND = () => `/warranty/abm/api/v2/admin/options/mobile-brands`;
export const POST_DESTROT_MOBILE_BRAND = () =>
  `/warranty/abm/api/v2/admin/options/mobile-brands/destroy`;
export const GET_MODEL_LIST = () => `/warranty/abm/api/v2/admin/options/mobile-models`;
export const POST_DESTROT_MODEL = () => `/warranty/abm/api/v2/admin/options/mobile-models/destroy`;
export const GET_PRODUCT_LIST = () => `/warranty/abm/api/v2/admin/options/products`;
export const POST_DESTROT_PRODUCT = () => `/warranty/abm/api/v2/admin/options/products/destroy`;
export const GET_PRODUCT_TYPE = () => `/warranty/abm/api/v2/admin/options/products/types`;
export const GET_SHIPPING_SELF = () => `/warranty/abm/api/v2/admin/options/shipping/self`;
export const POST_TRACKING_UPDATE = () => `/warranty/abm/api/v2/admin/tracking/update`;
export const GET_MAP_PRODUCT = (categoryCode) =>
  `/warranty/abm/api/v2/admin/options/map-products/full-search?/${categoryCode}`;
export const GET_MODEL_BY_ID = (mobileBrandId) =>
  `/warranty/abm/api/v2/admin/options/mobile-models/brand/id/${mobileBrandId}`;
export const POST_DESTROY_MANAGEMENT = () =>
  `/warranty/abm/api/v2/admin/options/map-products/destroy`;
export const GET_EDIT_MANAGEMENT = (mapProductId) =>
  `/warranty/abm/api/v2/admin/options/map-products/special-warranties/${mapProductId}`;
export const GET_EDIT_MANAGEMENT_PAGE = (mapProductId) =>
  `/warranty/abm/api/v2/admin/options/map-products/id/${mapProductId}`;
export const POST_DESTROY_MANAGEMENT_DATA = () =>
  `/warranty/abm/api/v2/admin/options/map-products/special-warranties/destroy`;
export const GET_CATEGORY_FULL_SEARCH = (obj) =>
  `/warranty/abm/api/v2/admin/options/categories/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_BRAND_FULL_SEARCH = (obj) =>
  `/warranty/abm/api/v2/admin/options/mobile-brands/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_PRODUCT_FULL_SEARCH = (obj) =>
  `/warranty/abm/api/v2/admin/options/products/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_MODEL_BRAND = (mobileBrandCode) =>
  `/warranty/abm/api/v2/admin/options/mobile-models/brand/${mobileBrandCode}`;
export const POST_CLAIM_PDF = () => `/warranty/abm/api/v2/admin/claims/cover-sheets`;
export const GET_USER_INFO = (obj) =>
  `/warranty/abm/api/v2/admin/customers/full-search/?${getQueryStringIgnoreEmpty(obj)}`;
export const POST_CHANGE_PASSWORD = () => `/warranty/abm/api/v2/admin/auth/password/init`;
export const POST_UPDATE_MAP_PRODUCT = () =>
  `/warranty/abm/api/v2/admin/options/map-products/update`;
export const POST_RESET_LINE = () => `/warranty/abm/api/v2/admin/customers/reset/lineuid`;
export const GET_PRODUCT_GROUP_CODE = () => `/warranty/abm/api/v2/admin/options/products/groups`;
export const GET_WARRANTY_DETAIL = (warrantyCode) =>
  `/warranty/abm/api/v2/admin/warranties/info/${warrantyCode}`;
export const GET_MAP_PRODUCT_FULL_SEARCH = (obj) =>
  `/warranty/abm/api/v2/admin/options/map-products/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_WARRANTY_FULL_SEARCH = (obj) =>
  `/warranty/abm/api/v2/admin/warranties/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_CLAIM_FULL_SEARCH = (obj) =>
  `/warranty/abm/api/v2/admin/claims/full-search?${getQueryStringIgnoreEmpty(obj)}`;
