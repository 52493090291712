import { useMutation } from '@tanstack/react-query';
import { GET, GET_CLAIM_DETAIL } from '../../../services';

export const useClaimCodeQuery = ({ reset, getValues }) =>
  useMutation({
    mutationFn: async (value) => {
      const response = await GET(GET_CLAIM_DETAIL(value));
      return response.result;
    },
    onSuccess: (result) => {
      const currentShippingId = getValues('shippingId');

      const claimFields = [
        'claimId',
        'claimCode',
        'trackingNumber',
        'shippingId',
        'fullName',
        'telephone',
        'addressFullText',
        'mobileBrandName',
        'mobileModelName',
        'remark',
        'claimStatusName',
      ];

      const claimData = claimFields.reduce((acc, field) => {
        acc[field] = result.claim[field];
        return acc;
      }, {});

      reset({
        ...getValues(),
        ...claimData,
        shippingId: currentShippingId || claimData.shippingId,
      });
    },
  });
