import React from 'react';
import { ImCheckmark } from 'react-icons/im';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBin6Line, RiTimeLine } from 'react-icons/ri';
import { color } from '../../../../resources';

export const requestStatusObg = {
  [10]: {
    icon: <BiEdit />,
    color: color.orangeTheme,
    label: 'ACTIVE (ลงทะเบียน)',
  },
  [20]: {
    icon: <ImCheckmark />,
    color: color.search2,
    label: 'CLAIM (เคลม)',
  },
  [30]: {
    icon: <RiTimeLine />,
    color: '#9e9e9e',
    label: 'EXPIRED (หมดอายุ)',
  },
  [40]: {
    icon: <RiDeleteBin6Line />,
    color: color.red2,
    label: 'CANCEL (ยกเลิก)',
  },
};
