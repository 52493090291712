import dayjs from 'dayjs';

export const FORM_SEARCH = ({ onEnter }) => {
  return [
    {
      name: 'search',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      type: 'INPUT',
      span: 14,
      helper: 'Tips : สามารถค้นหาด้วย รหัสออเดอร์, เลขคำสั่งซื้อ, เบอร์, รหัสลูกค้า, ชื่อลูกค้า',
      placeholder: 'ค้นหารายการสั่งซื้อ',
      onEnter,
    },
    {
      name: 'startDate',
      label: 'ค้นหาข้อมูลโดยอิงวันที่',
      type: 'DATE_RANGE',
      placeholder: '',
      span: 8,
    },
  ];
};

export const FORM_ADD_USER = ({ promotionsType, channelMember }) => {
  const span = 12;
  const accept = [`image/jpeg`, 'image/png', 'image/jpg', 'image/bmp', 'image/gif'];
  return [
    {
      name: 'invNo',
      label: 'เลขคำสั่งซื้อ',
      type: 'INPUT',
      placeholder: 'กรุณากรอกเลขคำสั่งซื้อ',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'telephone',
      label: 'เบอร์ติดต่อ',
      type: 'INPUT_PHONE',
      placeholder: 'กรุณากรอกเบอร์โทรศัพท์',
      maxLength: 10,
      span,
      rules: {
        required: true,
        pattern: /^[0-9]*$/,
      },
    },
    {
      name: 'promotionId',
      label: 'โปรโมชั่น',
      type: 'DROPDOWN',
      span: 24,
      placeholder: 'กรุณาเลือกโปรโมชั่น',
      properties: {
        options: promotionsType || [],
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'orderFrom',
      label: 'ช่องทาง',
      type: 'DROPDOWN',
      placeholder: 'กรุณาเลือกช่องทาง',
      span,
      properties: {
        options: channelMember || [],
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'status',
      label: 'สถานะ',
      type: 'DROPDOWN',
      span,
      placeholder: 'รอลงทะเบียน',
      properties: {
        options: [
          { value: '1', label: 'รอลงทะเบียน' },
          { value: '2', label: 'ไม่รอลงทะเบียน' },
        ],
      },
      disabled: true,
    },
    {
      name: 'orderDate',
      label: 'วันที่ซื้อ',
      type: 'DATE_PICKER',
      span: 12,
      rules: {
        required: true,
      },
      format: 'YYYY-MM-DD',
    },
    {
      name: 'qty',
      label: 'จำนวน (ชิ้น)',
      type: 'INPUT',
      placeholder: 'กรุณากรอกจำนวน',
      span,
      rules: {
        required: true,
      },
      pattern: /^[0-9]+$/,
    },
    {
      name: 'invImage',
      label: 'รูปใบเสร็จ (.png, .jpg, .jpeg, .bmp, .gif)',
      type: 'UPLOAD',
      accept: accept.join(','),
      listType: 'picture-card',
      span,
      maxCount: 1,
      rules: {
        required: true,
      },
    },
    {
      name: 'remark',
      label: 'หมายเหตุ (ระบุได้ไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: 'กรุณากรอกหมายเหตุ',
      span: 24,
      rules: {
        maxLength: 250,
      },
    },
  ];
};

export const FORM_EDIT_USER = ({ promotionsType, channelMember, orderStatus }) => {
  const span = 12;
  const accept = [`image/jpeg`, 'image/png', 'image/jpg', 'image/bmp', 'image/gif'];
  return [
    {
      name: 'invNo',
      label: 'เลขคำสั่งซื้อ',
      type: 'INPUT',
      span,
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      name: 'telephone',
      label: 'เบอร์โทรศัพท์',
      type: 'INPUT',
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'promotionId',
      label: 'โปรโมชั่น',
      type: 'DROPDOWN',
      span: 24,
      properties: {
        options: promotionsType || [],
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'orderFrom',
      label: 'ช่องทาง',
      type: 'DROPDOWN',
      span,
      properties: {
        options: channelMember || [],
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'orderStatus',
      label: 'สถานะ',
      type: 'DROPDOWN',
      span,
      placeholder: 'รอลงทะเบียน',
      properties: {
        options: orderStatus || [],
      },
    },
    {
      name: 'orderDate',
      label: 'วันที่ซื้อ',
      type: 'DATE_PICKER',
      span: 12,
      rules: {
        required: true,
      },
      format: 'YYYY-MM-DD',
    },
    {
      name: 'qty',
      label: 'จำนวน',
      type: 'INPUT',
      span,
      rules: {
        required: true,
      },
      pattern: /^[0-9]+$/,
    },
    {
      name: 'invImageUrl',
      label: 'รูปใบเสร็จ (.png, .jpg, .jpeg, .bmp, .gif)',
      type: 'UPLOAD',
      listType: 'picture-card',
      span,
      accept: accept.join(','),
      valuePropName: 'fileList',
      getValueFromEvent: (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
      },
      normalize: (value) => {
        if (typeof value === 'string') {
          return [
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: value,
            },
          ];
        }
        return value;
      },
    },
    {
      name: 'remark',
      label: 'หมายเหตุ (ระบุได้ไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      span: 24,
      rules: {
        maxLength: 250,
      },
    },
  ];
};

export const useFormState = (initialValues = {}) => {
  const defaultValues = {
    invNo: initialValues.invNo || '',
    telephone: initialValues.telephone || '',
    promotionId: initialValues.promotionId || '',
    orderFrom: initialValues.orderFrom || '',
    status: initialValues.status || '',
    orderDate: initialValues.orderDate || '',
    qty: initialValues.qty || '',
    remark: initialValues.remark || '',
    invImageUrl: initialValues.invImageUrl
      ? [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: initialValues.invImageUrl,
          },
        ]
      : [],
  };

  return {
    defaultValues,
  };
};

export const searchDefaultValue = {
  search: '',
  startDate: [dayjs().startOf('month'), dayjs()],
};
