import React from 'react';
import {
  BsFillCloudArrowDownFill,
  BsSearch,
  BsFillPenFill,
  BsFillTrashFill,
  BsBookHalf,
  BsFillXCircleFill,
  BsSave2Fill,
  BsPrinterFill,
  BsFillCheckCircleFill,
  BsLockFill,
  BsArrowLeftCircleFill,
  BsFillPlusCircleFill,
  BsArrowClockwise,
  BsShieldFillCheck,
  BsPersonCheckFill,
  BsKanban,
  BsJournalBookmarkFill,
  BsLayoutSidebarInsetReverse,
  BsMessenger,
  BsFillEmojiSmileFill,
  BsTruck,
  BsEye,
} from 'react-icons/bs';
import {
  RiLogoutBoxRFill,
  RiLoginBoxFill,
  RiTicket2Fill,
  RiEvernoteLine,
  RiFeedbackFill,
} from 'react-icons/ri';
import { IoSettingsOutline, IoBookmarks, IoAlertCircleSharp, IoCheckbox } from 'react-icons/io5';
import { HiDotsCircleHorizontal } from 'react-icons/hi';
import {
  FaChevronCircleDown,
  FaExternalLinkAlt,
  FaQrcode,
  FaFileAlt,
  FaHistory,
  FaCrown,
  FaShoppingBasket,
  FaTicketAlt,
  FaLine,
  FaPencilAlt,
  FaAngleDoubleLeft,
  FaPrint,
  FaUsers,
  FaStore,
  FaList,
  FaRegistered,
  FaCube,
  FaPaperPlane,
  FaHourglass,
  FaTimes,
  FaBook,
} from 'react-icons/fa';
import {
  MdWavingHand,
  MdFolderSpecial,
  MdOutlineCases,
  MdOutlineNavigateNext,
} from 'react-icons/md';
import { EditFilled, MoreOutlined } from '@ant-design/icons';
import { FiChevronRight } from 'react-icons/fi';
/**
 * React Icons --> https://react-icons.github.io/react-icons/
 */
export const Icon = {
  download: (props) => <BsFillCloudArrowDownFill {...props} />,
  search: (props) => <BsSearch {...props} />,
  edit: (props) => <BsFillPenFill {...props} />,
  remove: (props) => <BsFillTrashFill {...props} />,
  view: (props) => <BsBookHalf {...props} />,
  clear: (props) => <BsFillXCircleFill {...props} />,
  cancel: (props) => <BsFillXCircleFill {...props} />,
  logout: (props) => <RiLogoutBoxRFill {...props} />,
  login: (props) => <RiLoginBoxFill {...props} />,
  save: (props) => <BsSave2Fill {...props} />,
  print: (props) => <BsPrinterFill {...props} />,
  confirm: (props) => <BsFillCheckCircleFill {...props} />,
  forgetPassword: (props) => <BsLockFill {...props} />,
  back2: (props) => <BsArrowLeftCircleFill {...props} />,
  create: (props) => <BsFillPlusCircleFill {...props} />,
  reAct: (props) => <BsArrowClockwise {...props} />,
  recheck: (props) => <BsShieldFillCheck {...props} />,
  register: (props) => <BsPersonCheckFill {...props} />,
  settings: (props) => <IoSettingsOutline {...props} />,
  threeDots: (props) => <HiDotsCircleHorizontal {...props} />,
  downCircle: (props) => <FaChevronCircleDown {...props} />,
  bookmark: (props) => <IoBookmarks {...props} />,
  alert: (props) => <IoAlertCircleSharp {...props} />,
  checkboxSquare: (props) => <IoCheckbox {...props} />,
  wavingHand: (props) => <MdWavingHand {...props} />,
  external: (props) => <FaExternalLinkAlt {...props} />,
  qrcode: (props) => <FaQrcode {...props} />,
  fileSearch: (props) => <FaFileAlt {...props} />,
  message: (props) => <BsMessenger {...props} />,
  history: (props) => <FaHistory {...props} />,
  special: (props) => <MdFolderSpecial {...props} />,
  smile: (props) => <BsFillEmojiSmileFill {...props} />,
  crown: (props) => <FaCrown {...props} />,
  case: (props) => <MdOutlineCases {...props} />,
  shop: (props) => <FaShoppingBasket {...props} />,
  coupon: (props) => <FaTicketAlt {...props} />,
  ticket: (props) => <RiTicket2Fill {...props} />,
  outline: (props) => <MoreOutlined {...props} />,
  remark: (props) => <RiEvernoteLine {...props} />,
  next: (props) => <MdOutlineNavigateNext {...props} />,
  back: (props) => <RiFeedbackFill {...props} />,
  line: (props) => <FaLine {...props} />,
  pencil: (props) => <FaPencilAlt {...props} />,
  doubleLeft: (props) => <FaAngleDoubleLeft {...props} />,
  print2: (props) => <FaPrint {...props} />,
  users: (props) => <FaUsers {...props} />,
  store: (props) => <FaStore {...props} />,
  list: (props) => <FaList {...props} />,
  registered: (props) => <FaRegistered {...props} />,
  edit2: (props) => <EditFilled {...props} />,
  cube: (props) => <FaCube {...props} />,
  paperPlane: (props) => <FaPaperPlane {...props} />,
  hourglass: (props) => <FaHourglass {...props} />,
  times: (props) => <FaTimes {...props} />,
  truck: (props) => <BsTruck {...props} />,
  evernote: (props) => <FaBook {...props} />,
  detail: (props) => <BsEye {...props} />,
  /** MENU ICON */
  dashboard: (props) => <BsKanban {...props} />,
  todo: (props) => <BsJournalBookmarkFill {...props} />,
  formExample: (props) => <BsLayoutSidebarInsetReverse {...props} />,
};

export const StepIcon = () => {
  return <FiChevronRight size={20} style={{ color: 'grey' }} />;
};
