export const FORM_CLAIM_DETAIL_PERSONAL = () => {
  const span = 24;
  return [
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'TEXT',
      placeholder: '',
      span,
    },
    {
      name: 'telephone',
      label: 'หมายเลขโทรศัพท์',
      type: 'TEXT',
      placeholder: '',
      span,
    },
    {
      name: 'addressFullText',
      label: 'ที่อยู่',
      type: 'TEXT',
      span,
    },
    {
      name: 'zipcode',
      label: 'รหัสไปรษณีย์',
      type: 'TEXT',
      placeholder: '',
      span,
    },
    {
      name: 'provinceName',
      label: 'จังหวัด',
      type: 'TEXT',
      span,
    },
    {
      name: 'districtName',
      label: 'เขต',
      type: 'TEXT',
      span,
    },
    {
      name: 'subDistrictName',
      label: 'อำเภอ',
      type: 'TEXT',
      span,
    },
    {
      name: 'other',
      label: 'เพิ่มเติม',
      type: 'TEXT',
      placeholder: '',
      span,
    },
  ];
};

export const FORM_CLAIM_EDIT_DETAIL_PICTURE = () => {
  return [
    {
      name: 'claimImageUrl',
      label: 'รูปภาพสินค้าเคลม',
      type: 'PREVIEW',
      listType: 'picture-card',
      span: 24,
      maxCount: 1,
    },
  ];
};

export const FORM_CLAIM_DETAIL_CLAIM = () => {
  const span = 24;
  return [
    {
      name: 'claimCode',
      label: 'เลขที่การเคลม',
      type: 'TEXT',
      placeholder: '',
      span,
    },
    {
      name: 'claimId',
      label: 'Claim ID',
      type: 'HIDDEN',
      span,
    },
    {
      name: 'warrantyCode',
      label: 'รหัสลงทะเบียนสินค้า',
      type: 'TEXT',
      span,
    },
    {
      name: 'claimDate',
      label: 'วันที่แจ้งเคลม',
      type: 'TEXT',
      span,
    },
    {
      name: 'productName',
      label: 'ชื่อสินค้า',
      type: 'TEXT',
      span,
    },
    {
      name: 'mobileBrandName',
      label: 'ยี่ห้อ',
      type: 'TEXT',
      span,
    },
    {
      name: 'mobileModelName',
      label: 'รุ่น',
      type: 'TEXT',
      span,
    },
    {
      name: 'breakdownName',
      label: 'อาการเสีย',
      type: 'TEXT',
      span,
    },
    {
      name: 'detail1',
      label: 'รายละเอียด',
      type: 'TEXT',
      span,
    },
    {
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'TEXT',
      span,
    },
    {
      name: 'claimedDays',
      label: 'จำนวนวันที่แจ้งเคลมมาแล้ว',
      type: 'TEXT',
      span,
    },
    {
      name: 'remainingDays',
      label: 'จำนวนวันคงเหลือ',
      type: 'TEXT',
      span,
    },
  ];
};

export const FORM_REJECT = () => {
  return [
    {
      name: 'rejectReason',
      label: 'ข้อความ (ระบุได้ไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      span: 24,
      rules: {
        required: true,
        maxLength: 250,
      },
    },
  ];
};
